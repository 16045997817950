<template>
  <div class="o-company-create-csv">
    <input @change="fileChange" type="file">

    <div class="o-company-create-csv__table" v-if="workers.length">
      <div class="o-company-create-csv__table-header">
        <AHeading :level="2">プレビュー</AHeading>
        <AButton
            @click="clickButton" class="o-company-create-csv__button">登録</AButton>
      </div>
      <v-data-table :headers="headers" :items="workers" class="elevation-1">
        <template slot="items" slot-scope="props">
          <td class="text-xs-right">{{ props.item.companyName }}</td>
          <td class="text-xs-right">{{ props.item.companyNumber }}</td>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import AHeading from '@/components/atoms/ATypography/AHeading/index.vue'
import AButton from '@/components/atoms/AButton/index.vue'
import { mapActions } from 'vuex'

export default {
  name: 'OCompanyCreateCsv',
  components: {
    AHeading,
    AButton
  },
  data() {
    return {
      headers: [
        { text: "法人名", align: "left", value: "companyName" },
        { text: "法人番号", align: "left", value: "companyNumber" }
      ],
      workers: []
    }
  },
  methods: {
    ...mapActions('adminCompany', ['createCompanies']),
    fileChange(e) {
      const file = e.target.files[0];
      const reader = new FileReader();
      const workers = [];

      const loadFunc = () => {
        let lines = reader.result.split(/\r\n|\n|\r/);
        lines.shift();
        lines.forEach(element => {
          const workerData = element.split(",");
          if (workerData.length != 2) return;

          const worker = {
            companyName: workerData[0],
            companyNumber: workerData[1]
          };
          workers.push(worker);
        });
        this.workers = workers;
      };

      reader.onload = loadFunc;

      reader.readAsText(file);
    },
    clickButton() {
      try {
        this.createCompanies(this.workers)
        this.$router.push('/admin/companies')
      } catch(error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.o-company-create-csv {
  &__heading {
    margin: 20px auto;
  }
  &__table-header {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 12px;
  }
  &__button {
    display: inline-block;
    width: 100px;
  }
}
</style>
