<template>
  <div class="v-signin">
    <div class="v-signin-form">
      <MFormParts
        form-label="ログインID"
        form-type="input"
        placeholder="ログインID"
        :value="credentials.accountId"
        @input="handleLoginIdUpdate"
        />
      <MFormParts
        form-label="パスワード"
        form-type="password"
        placeholder="パスワード"
        :value="credentials.password"
        @input="handlePasswordUpdate"
        />
      <div class="signin__form-button">
        <AButton
          @click="login"
            >ログイン</AButton>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import MFormParts from '@/components/molecules/MFormParts/index.vue'
import AButton from '@/components/atoms/AButton/index.vue'

export default {
  name: 'AdminSignIn',
  components: {
    MFormParts,
    AButton
  },
  data() {
    return {
      credentials: {
        loginId: '',
        password: ''
      }
    }
  },
  methods: {
    ...mapActions('adminAuth', ['signin']),
    handleLoginIdUpdate({value}) {
      this.credentials.loginId = value
    },
    handlePasswordUpdate({value}) {
      this.credentials.password = value
    },
    login() {
      this.signin(this.credentials)
        .then(() => this.$router.push('/admin'))
    },
  }
}
</script>
