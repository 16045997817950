import Vue from 'vue'
import Vuex from 'vuex'
import auth from '@/store/modules/auth'
import users from '@/store/modules/users'
import adminAuth from '@/store/modules/admin/auth'
import adminCompany from '@/store/modules/admin/company'
import adminOffice from '@/store/modules/admin/office'
import adminUser from '@/store/modules/admin/user'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    users,
    adminAuth,
    adminCompany,
    adminOffice,
    adminUser
  }
})
