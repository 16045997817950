<template>
  <UserTable />
</template>

<script>
import UserTable from '@/components/UserTable.vue'

export default {
  name: 'Employment',
  components: {
    UserTable
  }
}
</script>

