<template>
  <v-container>
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-container>
          <h2>新規追加</h2>
          <v-form ref="form">
            <v-text-field
                v-model="user.name"
                :counter="20"
                label="名前"
                required></v-text-field>
            <v-text-field
                v-model="user.nameKana"
                :counter="20"
                label="ふりがな"></v-text-field>
            <v-text-field
                v-model="user.loginId"
                :counter="20"
                label="ログインID"
                required></v-text-field>
            <v-text-field
                v-model="user.password"
                :counter="10"
                label="パスワード"
                required></v-text-field>
            <v-btn @click="create">追加</v-btn>
            <v-btn @click="clear">クリア</v-btn>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>

    <v-toolbar flat color="grey lighten-2">
      <v-toolbar-title>社員情報一覧</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn color="primary" dark class="md-2" @click="showDialogNew">新規追加</v-btn>
    </v-toolbar>

    <v-data-table
        :headers="headers"
        :items="users"
        :options.sync="pagination"
        no-data-text="ユーザが登録されていません。"
        class="elevation-1">
      <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
      <template slot="items" slot-scope="props">
        <td>{{ props.item.name }}</td>
        <td>{{ props.item.nameKana }}</td>
        <td>{{ props.item.loginId }}</td>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: "UserTable",
  data: () => ({
    headers: [
      {text: '氏名', value: 'name'},
      {text: 'ふりがな', value: 'nameKana'},
      {text: 'ログインID', value: 'loginId'},
    ],
    pagination: {
      descending: true,
      rowsPerPage: 10
    },
    user: {
      name: '',
      nameKana: '',
      loginId: '',
      password: ''
    },
    progress: false,
    dialog: false,
  }),
  mounted() {
    this.getUsers()
  },
  computed: {
    ...mapGetters('users', ['users'])
  },
  methods: {
    ...mapActions('users', ['getUsers']),
    ...mapActions('auth', ['createUser']),
    create() {
      this.progress = true
      this.createUser(this.user)
        .then(() => this.getUsers())
      this.dialog = false
      this.progress = false
      this.$router.go({path: this.$router.currentRoute.path, force: true})
    },
    clear() {
      this.$refs.form.reset()
    },
    showDialogNew() {
      this.dialog = true
    }
  }
}
</script>

