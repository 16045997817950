<template>
  <div class="admin-menu">
    <ul>
      <li><router-link to="/admin">Home</router-link></li>
      <li><router-link to="/admin/companies">法人管理</router-link></li>
      <li><router-link to="/admin/offices/create">施設一括登録</router-link></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'AdminMenu'
}
</script>

<style lang="scss" scoped>
.admin-menu {
  li {
    list-style: none;
    a {
      color: #2e404e;
      display: block;
      width: 100%;
      text-decoration: none;
      padding: 10px 25px;
    }
    a:hover {
      background: #2e404e;
      color: #fefefe;
    }
  }
}
</style>
