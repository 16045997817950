import gql from 'graphql-tag'

export const ADMINISTRATORS = gql`
    query administrators {
        administrators {
            name
        }
    }
`

export const CURRENT_ADMINISTRATOR = gql`
    query currentAdministrator {
        currentAdministrator {
            name
        }
    }
`

