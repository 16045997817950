<template>
  <router-link to="/employment">従業員設定</router-link>
</template>

<script>

export default {
  name: 'Home',
}
</script>
