<template>
  <div>
    <v-toolbar flat color="grey lighten-2">
      <v-toolbar-title>法人一覧</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>

    <v-data-table
        :headers="headers"
        :items="companies"
        :options.sync="pagination"
        no-data-text="法人が登録されていません。"
        class="elevation-1">
      <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
      <template #item.companyName="{ item }">
        <a :href="`/admin/companies/${item.id}`">
          {{ item.companyName }}
        </a>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: "OCompanyTable",
  data: () => ({
    headers: [
      { text: '法人ID', value: 'id' },
      { text: '法人名', value: 'companyName' },
      { text: '法人番号', value: 'companyNumber' },
    ],
    pagination: {
      descending: true,
      rowsPerPage: 10
    },
    user: {
      companyName: '',
    },
    progress: false,
    dialog: false,
  }),
  mounted() {
    this.getCompanies()
  },
  computed: {
    ...mapGetters('adminCompany', ['companies'])
  },
  methods: {
    ...mapActions('adminCompany', ['getCompanies']),
  }
}
</script>

