import { apolloClient } from '@/vue-apollo'
import { GET_COMPANIES, GET_COMPANY } from '@/constants/company-query'
import { CREATE_COMPANIES } from '@/constants/company-mutation'

export default {
  namespaced: true,
  state: {
    company: {},
    companies: []
  },
  getters: {
    company: state => state.company,
    companies: state => state.companies
  },
  mutations: {
    SET_COMPANY (state, company) {
      state.company = company
    },
    SET_COMPANIES (state, companies) {
      state.companies = companies
    }
  },
  actions: {
    async getCompanies ({ commit }) {
      const { data } = await apolloClient.query({ query: GET_COMPANIES })
      commit('SET_COMPANIES', data.companies)
    },
    async createCompanies ({ dispatch }, companies) {
      try {
        await apolloClient.mutate({
          mutation: CREATE_COMPANIES,
          variables: {
            companies: companies
          }
        })
        dispatch('getCompanies')
      } catch (e) {
        console.log(e)
      }
    },
    async getCompany ({ commit }, companyId) {
      const { data } = await apolloClient.query({ query: GET_COMPANY,
        variables: { companyId }
      })
      commit('SET_COMPANY', data.findCompany)
    }
  }
}
