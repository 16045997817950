<template>
  <div class="app-admin">
    <div class="app-admin__header">
      MEBUKi Admins
    </div>
    <div class="app-admin__body">
      <div class="app-admin__main">
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
html, body {
  background: #eff0f4;
  height: 100%;
}

.app-admin {
  margin: 0 auto;
  height: 100%;
  &__header {
    color: #fefefe;
    margin: 0 auto;
    background: #4e5d69;
    padding: 16px;
  }
  &__body {
    height: 100%;
  }
  &__main {
    max-width: 200px;
    display: block;
    margin: 20px auto;
  }
}
</style>
