<template>
  <label class="a-label">
    <slot />
    <sup v-if="required" :class="labelRequired">*</sup>
  </label>
</template>

<script>
export default {
  name: 'ALabel',
  props: {
    required: {
      default: false
    }
  },
  computed: {
    labelRequired() {
      return this.required ? 'a-label--required' : '';
    }
  }
}
</script>

<style lang="scss" scoped>
.a-label {
  line-height: 1.5;
  font-size: 16px;
  font-weight: bold;
  color: #484848;

  &--required {
    margin-left: 2px;
    color: #ff6a6a;
  }
}
</style>
