import gql from 'graphql-tag'

export const CREATE_OFFICES = gql`
  mutation createOffices($offices: [CreateOffice]) {
    createOffices(input: {
      offices: $offices
    }) {
      officeName
    }
  }
`
