<template>
  <div class="admin-office-create">
    <AHeading :lebel="1" class="admin-office-create__heading">施設登録</AHeading>
    <OOfficeCreateCsv />
  </div>
</template>

<script>
import AHeading from '@/components/atoms/ATypography/AHeading/index.vue'
import OOfficeCreateCsv from '@/components/organisms/OOfficeCreateCsv/index.vue'

export default {
  name: 'AdminOfficeCreate',
  components: {
    AHeading,
    OOfficeCreateCsv
  }
}
</script>

<style lang="scss" scoped>
.admin-office-create {
  &__heading {
    margin: 20px auto;
  }
}
</style>
