<template>
  <div class="admin-company-create">
    <AHeading :level="1" class="admin-company-create__heading">法人追加</AHeading>
    <OCompanyCreateCsv />
  </div>
</template>

<script>
import AHeading from '@/components/atoms/ATypography/AHeading/index.vue'
import OCompanyCreateCsv from '@/components/organisms/OCompanyCreateCsv/index.vue'

export default {
  name: 'AdminCompanyCreate',
  components: {
    AHeading,
    OCompanyCreateCsv
  }
}
</script>

<style lang="scss" scoped>
.admin-company-create {
  &__heading {
    margin: 20px auto;
  }
}
</style>
