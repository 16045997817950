import Vue from 'vue'

import VueRouter from 'vue-router'
import store from '../store'
import Top from '@/views/Top.vue'
import SignIn from '@/views/SignIn.vue'
import Employment from '@/views/Employment.vue'
import AdminSignIn from '@/views/admin/SignIn.vue'
import AdminTop from '@/views/admin/Top.vue'
import AdminOfficeCreate from '@/views/admin/OfficeCreate.vue'
import AdminCompanyTable from '@/views/admin/CompanyTable.vue'
import AdminCompanyCreate from '@/views/admin/CompanyCreate.vue'
import AdminCompanyDetail from '@/views/admin/CompanyDetail.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Top',
    component: Top,
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'SignIn',
    component: SignIn
  },
  {
    path: '/employment',
    name: 'Employment',
    component: Employment,
    meta: { requiresAuth: true }
  },
  // Admin
  {
    path: '/admin/login',
    name: 'AdminSignIn',
    component: AdminSignIn,
    meta: {
      layout: 'admin-login',
    }
  },
  {
    path: '/admin',
    name: 'AdminTop',
    component: AdminTop,
    meta: {
      layout: 'admin',
      requiresAdminAuth: true
    }
  },
  {
    path: '/admin/offices/create',
    name: 'AdminOfficeCreate',
    component: AdminOfficeCreate,
    meta: {
      layout: 'admin',
      requiresAdminAuth: true
    }
  },
  {
    path: '/admin/companies',
    name: 'AdminCompanyTable',
    component: AdminCompanyTable,
    meta: {
      layout: 'admin',
      requiresAdminAuth: true
    }
  },
  {
    path: '/admin/companies/create',
    name: 'AdminCompanyCreate',
    component: AdminCompanyCreate,
    meta: {
      layout: 'admin',
      requiresAdminAuth: true
    }
  },
  {
    path: '/admin/companies/:companyId',
    name: 'AdminCompanyDetail',
    component: AdminCompanyDetail,
    meta: {
      layout: 'admin',
      requiresAdminAuth: true
    }
  },
  {
    path: '/admin/companies/:companyId/offices/:officeId',
    name: 'AdminOfficeDetail',
    component: () => import('@/views/admin/OfficeDetail.vue'),
    meta: {
      layout: 'admin',
      requiresAdminAuth: true
    }
  },
  {
    path: '/admin/companies/:companyId/offices/:officeId/users/create',
    name: 'AdminUserCreate',
    component: () => import('@/views/admin/UserCreate.vue'),
    meta: {
      layout: 'admin',
      requiresAdminAuth: true
    }
  },
  {
    path: '/tmp',
    name: 'Tmp',
    component: () => import('@/views/Tmp.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // Check if the user is logged in
  const isUserLoggedIn = store.getters['auth/isAuthenticated']
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isUserLoggedIn) {
      store.dispatch('auth/logOut')
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next()
  }

  const isAdminLoggedIn = store.getters['adminAuth/isAdminAuthenticated']
  if (to.matched.some(record => record.meta.requiresAdminAuth)) {
    if (!isAdminLoggedIn) {
      next({
        path: '/admin/login',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
