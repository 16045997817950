<template>
  <component :is="tagLevel" class="a-heading" :class="priorityClass">
    <slot />
  </component>
</template>

<script>
export default {
  name: 'AHeading',
  props: {
    level: {
      validator(level) {
        return [1, 2, 3].includes(level);
      },
      default: 1
    }
  },
  computed: {
    tagLevel() {
      return `h${this.level}`;
    },
    priorityClass() {
      return this.level ? `a-heading--${this.level}` : `a-heading--1`;
    }
  }
}
</script>

<style lang="scss" scoped>
.a-heading {
  line-height: 1.5;
  color: #484848;
  &--1 {
    font-size: 24px;
  }
  &--2 {
    font-size: 20px;
  }
  &--3 {
    font-size: 14px;
  }
}
</style>
