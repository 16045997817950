import { apolloClient } from '@/vue-apollo'
import { SIGNIN_ADMINISTRATOR } from '@/constants/administrator-mutation'
import { ADMINISTRATORS, CURRENT_ADMINISTRATOR } from '../../../constants/administrator-query'

const AUTH_TOKEN = 'administratorToken'

export default {
  namespaced: true,
  state: {
    administratorToken: '',
    administrator: {},
    administrators: [],
    authAdminStatus: false
  },
  getters: {
    isAdminAuthenticated: state => state.administratorToken !== '' || !!localStorage.getItem(AUTH_TOKEN),
    autAdminhStatus: state => state.authAdminStatus || !!localStorage.getItem(AUTH_TOKEN),
    administrator: state => state.administrator,
    administrators: state => state.administrators
  },
  mutations: {
    SET_ADMIN_TOKEN (state, administratorToken) {
      state.administratorToken = administratorToken
    },
    LOGIN_ADMINISTRATOR (state, administrator) {
      state.authAdminStatus = true
      state.administrator = { ...administrator }
    },
    SET_ADMINISTRATORS (state, administrators) {
      state.administrators = administrators
    }
  },
  actions: {
    async signin ({ commit }, credentials) {
      try {
        const { data } = await apolloClient.mutate({
          mutation: SIGNIN_ADMINISTRATOR,
          variables: {
            ...credentials
          }
        })
        const administratorToken = JSON.stringify(data.signinAdministrator.administratorToken)
        localStorage.setItem(AUTH_TOKEN, administratorToken)
        commit('SET_ADMIN_TOKEN', administratorToken)
        commit('LOGIN_ADMINISTRATOR', data.signinAdministrator.administrator)
      } catch (e) {
        console.log(e)
      }
    },
    async getAdministrators ({ commit }) {
      try {
        const { data } = await apolloClient.query({ query: ADMINISTRATORS })
        commit('SET_ADMINISTRATORS', data.administrators)
      } catch (e) {
        console.log(e)
      }
    },
    async getCurrentAdministrator ({ commit }) {
      try {
        const { data } = await apolloClient.query({ query: CURRENT_ADMINISTRATOR })
        commit('LOGIN_ADMINISTRATOR', data.currentAdministrator)
      } catch (e) {
        localStorage.removeItem(AUTH_TOKEN)
      }
    }
  }
}
