<template>
  <div class="header-block">
    <div class="header-block__logo">MEBUKi Admin</div>
    <div class="header-block__administrator">{{ administrator.name }}</div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'CAdminHeader',
  mounted() {
    try {
      this.getCurrentAdministrator()
    } catch(error) {
      this.router.push('/admin/login')
    }
  },
  computed: {
    ...mapGetters('adminAuth', ['administrator'])
  },
  methods: {
    ...mapActions('adminAuth', ['getCurrentAdministrator'])
  }
}
</script>

<style lang="scss" scoped>
.header-block {
  background: #4e5d69;
  display: flex;
  justify-content: space-between;
  &__logo {
    color: #fefefe;
    padding: 16px;
  }
  &__administrator {
    color: #fefefe;
    background: #2e404e;
    padding: 16px 25px;
  }
}
</style>
