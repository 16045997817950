<template>
  <button
    class="a-button"
    v-on="listeners"
    >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'AButton',
  computed: {
    listeners() {
      return { ...this.$listeners };
    }
  }
}
</script>

<style lang="scss" scoped>
.a-button {
  display: flex;
  align-items: center;
  justify-content: centerl;
  width: 100%;
  padding: 13px;
  background: #0071bc;
  color: #fefefe;
  border-radius: 4px;
  outline: none;
  font-size: 13px;
}
</style>
