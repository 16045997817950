import gql from 'graphql-tag'

export const CREATE_USER = gql`
    mutation createUser($name: String!, $nameKana: String, $loginId: ID!, $password: String!) {
        createUser(input: {
            name: $name,
            nameKana: $nameKana,
            authProvider: {
                credentials: {
                    loginId: $loginId,
                    password: $password
                }
            }
        }) {
            id
            name
            nameKana
            loginId
        }
    }
`

export const CREATE_USERS = gql`
  mutation createUsers($users: [CreateUser]) {
    createUsers(input: {
      users: $users
    }) {
      id
    }
  }
`

export const SIGNIN_USER = gql`
    mutation signinUser($loginId: ID!, $password: String!) {
        signinUser(input: {
            credentials: {
                loginId: $loginId,
                password: $password
            }
        }) {
            token
            user {
              name
            }
        }
    }
`
