<template>
  <component v-bind:is="layout"/>
</template>

<script>

export default {
  computed: {
    layout() {
      const defaultLayout = 'default';
      return (this.$route.meta.layout || defaultLayout) + '-layout';
    }
  }
}
</script>
