import { apolloClient } from '@/vue-apollo'
import { CREATE_OFFICES } from '@/constants/office-mutation'
import { OFFICE_DETAIL } from '@/constants/office-query'

export default {
  namespaced: true,
  state: {
    // TODO: いつか消す
    createOfficeSuccess: false,
    office: {}
  },
  getters: {
    office: state => state.office
  },
  mutations: {
    // TODO: いつか消す
    CREATE_OFFICE_SUCCESS (state) {
      state.createOfficeSuccess = true
    },
    updateOffice (state, office) {
      state.office = office
    }
  },
  actions: {
    async createOffices ({ commit }, offices) {
      try {
        await apolloClient.mutate({
          mutation: CREATE_OFFICES,
          variables: {
            offices: offices
          }
        })
        // TODO: いつか消す
        commit('CREATE_OFFICE_SUCCESS')
      } catch (e) {
        console.log(e)
      }
    },
    async getOffice({ commit }, officeId) {
      const { data } = await apolloClient.query({ query: OFFICE_DETAIL,
      variables: { officeId }
      })
      commit('updateOffice', data.officeDetail)
    }
  }
}
