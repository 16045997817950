import gql from 'graphql-tag'

export const ALL_USERS = gql`
    query allUsers {
        users {
            id
            name
            nameKana
            loginId
        }
    }
`

