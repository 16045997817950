import Vue from 'vue'
import App from './App.vue'
import { createProvider } from './vue-apollo'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import AdminLayout from '@/layouts/AdminLayout.vue'
import AdminLoginLayout from '@/layouts/AdminLoginLayout.vue'
import { schema } from './graphql-schema'

Vue.config.productionTip = false
Vue.component('default-layout', DefaultLayout);
Vue.component('admin-layout', AdminLayout);
Vue.component('admin-login-layout', AdminLoginLayout);

new Vue({
  schema,
  apolloProvider: createProvider(),
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
