<template>
  <div class="admin-company-table">
    <AHeading :level="1" class="admin-company-table__heading">法人管理</AHeading>
    <AButton
        class="admin-company-table__button"
        @click="goCompanyCreate">新規追加</AButton>
    <OCompanyTable />
  </div>
</template>

<script>
import AHeading from '@/components/atoms/ATypography/AHeading/index.vue'
import AButton from '@/components/atoms/AButton/index.vue'
import OCompanyTable from '@/components/organisms/OCompanyTable/index.vue'

export default {
  name: 'AdminCompanyTable',
  components: {
    AHeading,
    OCompanyTable,
    AButton
  },
  methods: {
    goCompanyCreate() {
      this.$router.push('/admin/companies/create')
    }
  }
}
</script>

<style lang="scss" scoped>
.admin-company-table {
  &__heading {
    margin: 20px auto;
  }
  &__button {
    width: 100px;
    display: block;
    margin: 0 0 24px auto;
  }
}
</style>
