<template>
  <p class="a-body">
    <slot />
  </p>
</template>

<script>
export default {
  name: 'ABody',
}
</script>

<style lang="scss" scoped>
.a-body {
  line-height: 1.5;
  font-size: 14px;
  color: #484848;
}
</style>
