<template>
  <div class="o-company-detail">
    <div class="o-company-detail__item">
      <ALabel>法人ID</ALabel>
      <ABody>{{ company.id }}</ABody>
    </div>
    <div class="o-company-detail__item">
      <ALabel>法人名</ALabel>
      <ABody>{{ company.companyName }}</ABody>
    </div>
    <div class="o-company-detail__item">
      <ALabel>法人番号</ALabel>
      <ABody>{{ company.companyNumber }}</ABody>
    </div>

    <div class="o-company-detail__office-table">
      <v-toolbar flat color="grey lighten-2" class="o-company-detail__office-table-header">
        <v-toolbar-title>事業所一覧</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-data-table
          :headers="headers"
          :items="company.offices"
          :options.sync="pagination"
          no-data-text="事業所が登録されていません。"
          class="o-company-detail__office-table-body">
        <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
        <template #item.officeName="{ item }">
          <a :href="`/admin/companies/${company.id}/offices/${item.id}`">
            {{ item.officeName }}
          </a>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ALabel from '@/components/atoms/ATypography/ALabel/index'
import ABody from '@/components/atoms/ATypography/ABody/index'

export default {
  name: 'OCompanyDetail',
  components: {
    ALabel,
    ABody
  },
  props: {
    companyId: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    headers: [
      { text: '事業所ID', value: 'id' },
      { text: '事業所名', value: 'officeName' },
      { text: '事業所住所', value: 'officeAddress' },
      { text: '事業所電話番号', value: 'officeTel' },
    ],
    pagination: {
      descending: true,
      rowsPerPage: 10
    },
    user: {
      companyName: '',
    },
  }),
  mounted() {
    this.getCompany(this.companyId)
  },
  computed: {
    ...mapGetters('adminCompany', ['company'])
  },
  methods: {
    ...mapActions('adminCompany', ['getCompany']),
  }
}
</script>

<style lang="scss" scoped>
.o-company-detail {
  &__office-table {
    margin: 20px auto;
  }
}
</style>
