<template>
  <router-link
    v-if="to"
    :to="to"
    class="a-link"
  >
    <slot />
  </router-link>
  <a
    v-else-if="href"
    :href="href"
    class="a-link"
    :target="targetBlank"
  >
    <slot />
  </a>
  <a
    v-else-if="back"
    class="a-link"
    @click="$router.back()"
  >
    <slot />
  </a>
</template>

<script>
export default {
  name: 'ALink',
  props: {
    to: {
      type: String,
      default: ''
    },
    href: {
      type: String,
      default: ''
    },
    back: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.a-link {
  font-size: 14px;
  color: #1179ce;
  text-decoration: none;
}
.a-link:hover {
  cursor: pointer;
}
</style>
