<template>
  <header>
    <div>MEBUKI</div>
    <div v-if="authStatus" id="nav">
      <div>{{user.name}}</div>
      <button class="auth-button" @click="logOut" > Log Out</button>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  methods: {
    logOut: function () {
      this.$store.dispatch('auth/logOut')
        .then(() => this.$router.push('/login'))
    }
  },
  computed: {
    ...mapGetters('auth', ['authStatus', 'user'])
  }
}
</script>
