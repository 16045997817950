<template>
  <div class="admin-company-detail">
    <AHeading :level="1" class="admin-company-detail__heading">法人詳細</AHeading>
    <ALink back>&lt; 戻る</ALink>
    <OCompanyDetail :companyId="companyId"/>
  </div>
</template>

<script>
import AHeading from '@/components/atoms/ATypography/AHeading/index.vue'
import OCompanyDetail from '@/components/organisms/OCompanyDetail/index.vue'
import ALink from '@/components/atoms/ATypography/ALink/index.vue'

export default {
  name: 'AdminCompanyDetail',
  components: {
    AHeading,
    OCompanyDetail,
    ALink
  },
  data() {
    return {
      companyId: 0
    }
  },
  created() {
    this.companyId = Number(this.$route.params.companyId)
  }
}
</script>

<style lang="scss" scoped>
.admin-company-detail {
  &__heading {
    margin: 20px auto;
  }
}
</style>
