import gql from 'graphql-tag'

export const SIGNIN_ADMINISTRATOR = gql`
    mutation signinAdministrator($loginId: ID!, $password: String!) {
        signinAdministrator(input: {
            credentials: {
                loginId: $loginId,
                password: $password
            }
        }) {
            administratorToken
            administrator {
              name
            }
        }
    }
`
