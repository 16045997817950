<template>
  <div class="m-form-parts">
    <ALabel>{{ formLabel }}</ALabel>
    <div class="m-form-parts__body">
      <AInputText
        v-if="formType === 'input'"
        :value="value"
        :placeholder="placeholder"
        @input="handleInputUpdate"
      />
      <AInputPassword
        v-if="formType === 'password'"
        :value="value"
        :placeholder="placeholder"
        @input="handleInputUpdate"
      />
    </div>
  </div>
</template>

<script>
import ALabel from '@/components/atoms/ATypography/ALabel/index.vue'
import AInputText from '@/components/atoms/AForm/AInputText/index.vue'
import AInputPassword from '@/components/atoms/AForm/AInputPassword/index.vue'

export default {
  name: 'MFormParts',
  components: {
    ALabel,
    AInputText,
    AInputPassword
  },
  props: {
    formLabel: {
      default: '',
      required: true
    },
    formType: {
      default: ''
    },
    value: {
      default: ''
    },
    placeholder: {
      default: ''
    }
  },
  methods: {
    handleInputUpdate({ value }) {
      this.$emit('input', { value });
    }
  }
}
</script>
