// Code generated by backend/lib/tasks/static_code_converters.rake; DO NOT EDIT.

export const codePositionOptions = [
  {
    "id": 1,
    "code": "general_manager",
    "name": "本部長"
  },
  {
    "id": 2,
    "code": "office_manager",
    "name": "施設長、事務長"
  },
  {
    "id": 3,
    "code": "manager",
    "name": "課長、係長"
  },
  {
    "id": 4,
    "code": "chief",
    "name": "主任、サービス管理責任者"
  },
  {
    "id": 5,
    "code": "unit_leader",
    "name": "ユニットリーダー"
  },
  {
    "id": 6,
    "code": "experience_employment",
    "name": "一般職（経験あり）"
  },
  {
    "id": 7,
    "code": "inexperience_employment",
    "name": "一般職（無資格、未経験）"
  }
]
