<template>
  <div class="o-office-create-csv">
    <input @change="fileChange" type="file" id="file_input_expense" name="file_input_expense">
    <div class="o-office-create-csv__table" v-if="workers.length">
      <div class="o-office-create-csv__table-header">
        <AHeading :level="2">プレビュー</AHeading>
        <AButton
            @click="clickButton" class="o-office-create-csv__button">登録</AButton>
      </div>
      <v-data-table :headers="headers" :items="workers" class="elevation-1">
        <template slot="items" slot-scope="props">
          <td class="text-xs-right">{{ props.item }}</td>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import AHeading from '@/components/atoms/ATypography/AHeading/index.vue'
import AButton from '@/components/atoms/AButton/index.vue'
import { codePositionOptions } from '@/config/staticSelectCodePosition'

export default {
  name: 'OOfficeCreateCsv',
  components: {
    AHeading,
    AButton
  },
  data() {
    return {
      // header
      headers: [
        { text: "法人ID", align: "left", sortable: false, value: "companyId" },
        { text: "事業所名", align: "left", sortable: false, value: "officeName" },
        { text: "事業所住所", align: "left", sortable: false, value: "officeAddress" },
        { text: "窓口担当者役職", align: "left", sortable: false, value: "contactPersonPosition" },
        { text: "窓口担当者名", align: "left", sortable: false, value: "contactPersonName" },
        { text: "窓口担当者メールアドレス", align: "left", sortable: false, value: "contactPersonEmail" },
        { text: "事業所電話番号", align: "left", sortable: false, value: "officeTel" },
        { text: "請求先住所", align: "left", sortable: false, value: "invoiceAddress" },
        { text: "請求担当者役職", align: "left", sortable: false, value: "invoicePersonPosition" },
        { text: "請求担当者名", align: "left", sortable: false, value: "invoicePersonName" },
        { text: "請求先メールアドレス", align: "left", sortable: false, value: "invoicePersonEmail" },
        { text: "請求先電話番号", align: "left", sortable: false, value: "invoiceTel" },
      ],
      workers: []
    }
  },
  mounted() {
    this.getAdministrators()
  },
  computed: {
    ...mapGetters('adminAuth', ['administrators'])
  },
  methods: {
    ...mapActions('adminAuth', ['getAdministrators']),
    ...mapActions('adminOffice', ['createOffices']),
    handleLoginIdUpdate({value}) {
      this.credentials.loginId = value
    },
    handlePasswordUpdate({value}) {
      this.credentials.password = value
    },
    login() {
      this.signin(this.credentials)
        .then(() => this.$router.push('/admin'))
    },
    fileChange: function(e) {
      const file = e.target.files[0];
      const reader = new FileReader();
      const workers = [];

      const loadFunc = () => {
        let lines = reader.result.split(/\r\n|\n|\r/);
        lines.shift();
        lines.forEach(element => {
          const workerData = element.split(",");
          if (workerData.length != 12) return;
          const worker = {
            companyId: workerData[0],
            officeName: workerData[1],
            officeAddress: workerData[2],
            contactPersonPosition: workerData[3],
            contactPersonName: workerData[4],
            contactPersonEmail: workerData[5],
            officeTel: workerData[6],
            invoiceAddress: workerData[7],
            invoicePersonPosition: workerData[8],
            invoicePersonName: workerData[9],
            invoicePersonEmail: workerData[10],
            invoiceTel: workerData[11]
          };
          workers.push(worker);
        });
        this.workers = workers;
      };

      reader.onload = loadFunc;

      reader.readAsText(file);
    },
    clickButton() {

      const offices = [];
      this.workers.forEach(work => {
        const contactPersonPositions = codePositionOptions.filter(x => x.name === work.contactPersonPosition)
        const invoicePersonPositions = codePositionOptions.filter(x => x.name === work.invoicePersonPosition)
        const office = {
          companyId: parseInt(work.companyId),
          officeName: work.officeName,
          officeAddress: work.officeAddress,
          contactPersonPosition: contactPersonPositions ? contactPersonPositions[0].code : undefined,
          contactPersonName: work.contactPersonName,
          contactPersonEmail: work.contactPersonEmail,
          officeTel: work.officeTel,
          invoiceAddress: work.invoiceAddress,
          invoicePersonPosition: invoicePersonPositions ? invoicePersonPositions[0].code : undefined,
          invoicePersonName: work.invoicePersonName,
          invoicePersonEmail: work.invoicePersonEmail,
          invoiceTel: work.invoiceTel
        };
        offices.push(office)
      })
      // 登録処理
      this.createOffices(offices)
    }
  }
}
</script>

<style lang="scss" scoped>
.o-office-create-csv {
  &__heading {
    margin: 20px auto;
  }
  &__table-header {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 12px;
  }
  &__button {
    display: inline-block;
    width: 100px;
  }
}
</style>
