import gql from 'graphql-tag'

export const GET_COMPANIES = gql`
    query getCompanies {
        companies {
            id
            companyName
            companyNumber
        }
    }
`

export const GET_COMPANY = gql`
  query getCompany($companyId: Int!) {
    findCompany(companyId: $companyId) {
      id
      companyName
      companyNumber
      offices {
        id
        officeName
        officeAddress
        officeTel
      }
    }
  }
`
