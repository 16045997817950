<template>
  <div class="a-input-password">
    <input
      ref="input"
      type="password"
      class="a-input-password__body"
      :value="value"
      :placeholder="placeholder"
      :maxlength="maxLength"
      @input="updateValue"
    />
  </div>
</template>

<script>
export default {
  name: 'AInputPassword',
  props: {
    required: {
      default: ''
    },
    value: {
      default: ''
    },
    placeholder: {
      default: ''
    },
    maxLength: {
      default: 2000
    }
  },
  methods: {
    updateValue(event) {
      this.$emit('input', {
        value: event.target.value.trim()
      })
    }
  }
}
</script>
