<template>
  <div class="app-admin">
    <div class="app-admin__header">
      <CAdminHeader />
    </div>
    <div class="app-admin__body">
      <div class="app-admin__side">
        <CAdminMenu />
      </div>
      <div class="app-admin__main">
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
import CAdminHeader from '@/components/commons/CAdminHeader/index.vue'
import CAdminMenu from '@/components/commons/CAdminMenu/index.vue'

export default {
  components: {
    CAdminHeader,
    CAdminMenu
  }
}
</script>

<style lang="scss">
html, body {
  background: #eff0f4;
  height: 100%;
}

.app-admin {
  margin: 0 auto;
  height: 100%;
  &__header {
    margin: 0 auto;
  }
  &__body {
  display: flex;
  height: 100%;
  }
  &__side {
    border-right: 1px solid #dddddd;
    min-height: 100%;
    width: 17%;
  }
  &__main {
    float: right;
    width: 80%;
    height:100%;
    margin: 0 auto;
  }
}
</style>
