import gql from 'graphql-tag'

export const CREATE_COMPANIES = gql`
  mutation createCompanies($companies: [CreateCompany]) {
    createCompanies(input: {
      companies: $companies
    }) {
      companyName
      companyNumber
    }
  }
`
