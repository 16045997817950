import { apolloClient } from '@/vue-apollo'
import { CREATE_USERS } from '@/constants/user-mutation'

export default {
  namespaced: true,
  state: {
    // TODO: いつか消す
    createUsersSuccess: false
  },
  getters: {},
  mutations: {
    // TODO: いつか消す
    updateUserSuccess (state) {
      state.createUsersSuccess = true
    }
  },
  actions: {
    async createUsers ({ commit }, users) {
      console.log(users)
      try {
        await apolloClient.mutate({
          mutation: CREATE_USERS,
          variables: {
            users: users
          }
        })
        // TODO: いつか消す
        commit('updateUserSuccess')
      } catch (e) {
        console.log(e)
      }
    }
  }
}
