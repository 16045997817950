import gql from 'graphql-tag'

export const OFFICE_DETAIL = gql`
  query getOffice($officeId: Int!) {
    officeDetail(officeId: $officeId) {
      id
      officeName
      officeAddress
      officeTel
      contactPersonEmail
      contactPersonPosition {
        code
        name
      }
      contactPersonName
      invoiceAddress
      invoiceTel
      invoicePersonEmail
      invoicePersonPosition {
        code
        name
      }
      invoicePersonName
      users {
        id
        name
      }
    }
  }
`
