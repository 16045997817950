import { onLogout, apolloClient } from '@/vue-apollo'
import { SIGNIN_USER, CREATE_USER } from '@/constants/user-mutation'

const AUTH_TOKEN = 'token'

export default {
  namespaced: true,
  state: {
    token: '',
    user: {},
    authStatus: false
  },
  getters: {
    isAuthenticated: state => !!state.token || !!localStorage.getItem(AUTH_TOKEN),
    authStatus: state => state.authStatus || !!localStorage.getItem(AUTH_TOKEN),
    user: state => state.user
  },
  mutations: {
    SET_TOKEN (state, token) {
      state.token = token
    },
    LOGIN_USER (state, user) {
      state.authStatus = true
      state.user = { ...user }
    },
    LOGOUT_USER (state) {
      state.authStatus = ''
      state.token = '' && localStorage.removeItem(AUTH_TOKEN)
    }
  },
  actions: {
    async signin ({ commit, dispatch }, credentials) {
      try {
        const { data } = await apolloClient.mutate({
          mutation: SIGNIN_USER,
          variables: {
            ...credentials
          }
        })
        const token = JSON.stringify(data.signinUser.token)
        localStorage.setItem(AUTH_TOKEN, token)
        commit('SET_TOKEN', token)
        commit('LOGIN_USER', data.signinUser.user)
        dispatch('getUsers')
      } catch (e) {
        console.log(e)
      }
    },
    async createUser ({ dispatch}, user) {
      try {
        await apolloClient.mutate({
          mutation: CREATE_USER,
          variables: {
            ...user
          }
        })
        dispatch('getUsers')
      } catch (e) {
        console.log(e)
      }
    },
    async logOut ({ commit }) {
      commit('LOGOUT_USER')
      onLogout(apolloClient)
    }
  }
}
