import { apolloClient } from '@/vue-apollo'
import { ALL_USERS } from '@/constants/user-query'

export default {
  namespaced: true,
  state: {
    users: []
  },
  getters: {
    users: state => state.users
  },
  mutations: {
    USERS (state, users) {
      state.users = users
    }
  },
  actions: {
    async getUsers ({ commit }) {
      const { data } = await apolloClient.query({ query: ALL_USERS })
      commit('USERS', data.users)
    }
  }
}
